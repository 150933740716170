<template>
  <div>
    <BLoading :is-full-page="false" :active="loading" />
    <template v-if="!loading">
      <BMessage v-if="!tags" type="is-danger" has-icon icon-size="is-small">
        <h2 class="mb-3">
          {{ $t('error') }}
        </h2>
        <p>{{ $t('unexpected-error-info-message') }}</p>
      </BMessage>
      <template v-else>
        <div class="columns">
          <div class="column is-one-quarter">
            <BInput
              v-model="searchString"
              :placeholder="$t('search')"
              type="search"
              icon="search"
            />
          </div>

          <div class="column is-one-half has-text-right-desktop">
            <button class="button is-link" @click="startTagCreation()">
              {{ $t('button-new-tag') }}
            </button>
          </div>
        </div>

        <BTable
          :data="filteredTags"
          paginated
          :per-page="perPage"
          pagination-size="is-small"
        >
          <BTableColumn
            v-slot="props"
            :field="'name'"
            :label="$t('name')"
            sortable
          >
            {{ props.row.name }}
          </BTableColumn>

          <BTableColumn
            v-slot="props"
            :field="'creatorName'"
            :label="$t('creator')"
            sortable
          >
            {{ props.row.creatorName }}
          </BTableColumn>

          <BTableColumn
            v-slot="props"
            :field="'created'"
            :label="$t('created')"
            sortable
          >
            {{ Number(props.row.created) | date('ll') }}
          </BTableColumn>

          <BTableColumn v-slot="props" label=" " centered>
            <div class="buttons">
              <button
                class="button is-small is-link"
                @click="startTagEdition(props.row)"
              >
                {{ $t('edit') }}
              </button>
              <button
                class="button is-small is-danger"
                @click="deleteTagDialog(props.row)"
              >
                {{ $t('delete') }}
              </button>
            </div>
          </BTableColumn>

          <template #empty>
            <div class="content has-text-grey has-text-centered">
              <p>{{ $t('no-tag-fitting-criteria') }}</p>
            </div>
          </template>

          <template #bottom-left>
            <BSelect v-model="perPage" size="is-small">
              <option value="10">
                $t('count-per-page', { count: 10 }) }}
              </option>
              <option value="25">
                {{ $t('count-per-page', { count: 25 }) }}
              </option>
              <option value="50">
                {{ $t('count-per-page', { count: 50 }) }}
              </option>
              <option value="100">
                {{ $t('count-per-page', { count: 100 }) }}
              </option>
            </BSelect>
          </template>
        </BTable>

        <TagModal
          :active.sync="modal"
          :tag="editedTag"
          @addTag="addTag"
          @updateTag="updateTag"
        />
      </template>
    </template>
  </div>
</template>

<script>
import { TagCollection } from 'cytomine-client';
import { getWildcardRegexp } from '@/utils/string-utils';
import TagModal from '@/components/tag/TagModal';

export default {
  name: 'AdminTags',
  components: {
    TagModal,
  },
  data() {
    return {
      loading: true,
      tags: null,
      addTagModal: false,
      searchString: '',
      perPage: 25,
      modal: false,
      editedTag: null,
    };
  },
  computed: {
    regexp() {
      return getWildcardRegexp(this.searchString);
    },
    filteredTags() {
      if (!this.searchString) {
        return this.tags;
      }

      return this.tags.filter((ts) => this.regexp.test(ts.name));
    },
  },
  async created() {
    try {
      this.tags = (await TagCollection.fetchAll()).array;
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
  },
  methods: {
    startTagCreation() {
      this.editedTag = null;
      this.modal = true;
    },
    addTag(tag) {
      this.tags.push(tag);
    },
    startTagEdition(tag) {
      this.editedTag = tag;
      this.modal = true;
    },
    updateTag(tag) {
      this.editedTag.populate(tag);
    },

    deleteTagDialog(tag) {
      this.$buefy.dialog.confirm({
        title: this.$t('delete'),
        message: this.$t('delete-tag-confirmation-message', {
          tagName: tag.name,
        }),
        type: 'is-danger',
        confirmText: this.$t('confirm'),
        cancelText: this.$t('cancel'),
        onConfirm: () => this.deleteTag(tag),
      });
    },
    deleteTag(tag) {
      try {
        tag.delete();
        this.tags.splice(this.tags.indexOf(tag), 1);
        this.$notify({
          type: 'success',
          text: this.$t('notif-success-tag-delete', { tagName: tag.name }),
        });
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-tag-delete', {
            tagName: this.currentTag.name,
          }),
        });
      }
    },
  },
};
</script>
