<template>
  <div>
    <BLoading v-if="loading" :is-full-page="false" active />

    <BMessage v-else-if="error" type="is-danger" has-icon icon-size="is-small">
      <h2 class="mb-3">
        {{ $t('error') }}
      </h2>
      <p>{{ $t('unexpected-error-info-message') }}</p>
    </BMessage>

    <template v-else>
      <div class="columns">
        <div class="column is-one-quarter">
          <BInput
            v-model="searchString"
            :placeholder="$t('search')"
            type="search"
            icon="search"
          />
        </div>

        <div class="column is-one-half has-text-right-desktop">
          <IdxBtn color="blue" @click="startUserCreation">
            {{ $t('button-new-user') }}
          </IdxBtn>
        </div>
      </div>

      <CytomineTable
        :collection="userCollection"
        :current-page.sync="currentPage"
        :per-page.sync="perPage"
        :sort.sync="sortField"
        :order.sync="sortOrder"
        detailed
        :revision="revision"
      >
        <template #default>
          <BTableColumn
            v-slot="props"
            :label="$t('username')"
            field="username"
            sortable
            width="100"
          >
            {{ props.row.username }}
          </BTableColumn>

          <BTableColumn
            v-slot="props"
            :label="$t('name')"
            field="fullName"
            sortable
            width="150"
          >
            {{ props.row.firstname }} {{ props.row.lastname }}
          </BTableColumn>

          <BTableColumn
            v-slot="props"
            :label="$t('role')"
            field="role"
            sortable
            width="50"
          >
            <span :class="getRoleData(props.row).class" class="tag">{{
              $t(getRoleData(props.row).label)
            }}</span>
          </BTableColumn>

          <BTableColumn
            v-slot="props"
            :label="$t('email')"
            field="email"
            sortable
            width="150"
          >
            <a :href="`mailto:${props.row.email}`">{{ props.row.email }}</a>
          </BTableColumn>

          <BTableColumn
            v-slot="props"
            :label="$t('source')"
            field="origin"
            centered
            sortable
            width="50"
          >
            <span :class="['tag', props.row.LDAP ? 'is-link' : 'is-grey']">
              {{ displayMemberOrigin(props.row) }}
            </span>
          </BTableColumn>

          <BTableColumn
            v-slot="props"
            :label="$t('created')"
            field="created"
            sortable
            width="150"
          >
            {{ Number(props.row.created) | date('ll LT') }}
          </BTableColumn>

          <BTableColumn
            v-slot="props"
            :label="$t('updated')"
            field="updated"
            sortable
            width="150"
          >
            <template v-if="props.row.updated">
              {{ Number(props.row.updated) | date('ll LT') }}
            </template>
            <template v-else>
              -
            </template>
          </BTableColumn>

          <BTableColumn v-slot="props" label="" width="100">
            <div class="flex flex-wrap gap-8">
              <IdxBtn
                color="primary"
                class="is-small"
                @click="startUserEdition(props.row)"
              >
                {{ $t('edit') }}
              </IdxBtn>
              <IdxBtn
                v-if="props.row.enabled"
                color="danger"
                class="is-small"
                @click="lock(props.row)"
              >
                {{ $t('button-lock') }}
              </IdxBtn>
              <IdxBtn
                v-else
                color="success"
                class="is-small"
                @click="unlock(props.row)"
              >
                {{ $t('button-unlock') }}
              </IdxBtn>
            </div>
          </BTableColumn>
        </template>

        <template #detail="{ row: user }">
          <UserDetails :user="user" />
        </template>

        <template #empty>
          <div class="content has-text-grey has-text-centered">
            <p>{{ $t('no-user-fitting-criteria') }}</p>
          </div>
        </template>
      </CytomineTable>

      <UserModal
        :active.sync="modal"
        :user="editedUser"
        @addUser="refreshUsers"
        @updateUser="updateUser"
      />
    </template>
  </div>
</template>

<script>
import { UserCollection } from 'cytomine-client';
import UserModal from './UserModal.vue';
import UserDetails from './UserDetails.vue';
import { rolesMapping } from '@/utils/role-utils.js';
import CytomineTable from '@/components/utils/CytomineTable.vue';

export default {
  name: 'AdminUsers',
  components: {
    CytomineTable,
    UserModal,
    UserDetails,
  },
  data() {
    return {
      loading: true,
      error: false,
      addUserModal: false,
      searchString: '',
      currentPage: 1,
      perPage: 25,
      sortField: 'username',
      sortOrder: '',
      revision: 0,
      modal: false,
      editedUser: null,
    };
  },
  computed: {
    roles() {
      return rolesMapping;
    },
    userCollection() {
      const collection = new UserCollection({
        withRoles: true,
      });
      if (this.searchString) {
        collection['fullName'] = {
          ilike: encodeURIComponent(this.searchString),
        };
      }

      return collection;
    },
  },
  async created() {
    this.revision++;
    this.loading = false;
  },
  methods: {
    displayMemberOrigin(member) {
      let key;
      if (member.origin === 'LDAP') key = 'LDAP';
      if (member.origin === 'BOOTSTRAP') key = 'system';
      else key = 'manual';

      return this.$t(key);
    },
    getRoleData(user) {
      return this.roles[user.role];
    },
    async lock(user) {
      try {
        await user.lock();
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-user-lock'),
        });
      }
    },
    async unlock(user) {
      try {
        await user.unlock();
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-user-unlock'),
        });
      }
    },
    startUserCreation() {
      this.editedUser = null;
      this.modal = true;
    },
    refreshUsers() {
      this.revision++;
    },
    startUserEdition(user) {
      this.editedUser = user;
      this.modal = true;
    },
    updateUser(user) {
      this.revision++;
      this.editedUser.populate(user);
    },
  },
};
</script>
