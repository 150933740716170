<template>
  <form @submit.prevent="save()">
    <CytomineModal
      :active="active"
      :title="title"
      @close="$emit('update:active', false)"
    >
      <b-field
        :label="$t('name')"
        :type="{ 'is-danger': errors.has('name') }"
        :message="errors.first('name')"
      >
        <b-input
          v-model="internalTag['name']"
          v-validate="'required'"
          name="name"
        />
      </b-field>

      <template #footer>
        <button
          class="button"
          type="button"
          @click="$emit('update:active', false)"
        >
          {{ $t('cancel') }}
        </button>
        <button :disabled="errors.any()" class="button is-link">
          {{ $t('save') }}
        </button>
      </template>
    </CytomineModal>
  </form>
</template>

<script>
import { Tag } from 'cytomine-client';
import CytomineModal from '@/components/utils/CytomineModal';

export default {
  name: 'TagModal',
  components: { CytomineModal },
  props: {
    active: Boolean,
    tag: Object,
  },
  $_veeValidate: { validator: 'new' },
  data() {
    return {
      internalTag: {},
      displayErrors: false,
    };
  },
  computed: {
    editionMode() {
      return Boolean(this.tag);
    },
    title() {
      return this.$t(this.editionMode ? 'update-tag' : 'create-tag');
    },
  },
  watch: {
    active(val) {
      if (val) {
        this.internalTag = this.tag ? this.tag.clone() : new Tag();
        this.displayErrors = false;
      }
    },
  },
  methods: {
    async save() {
      const result = await this.$validator.validateAll();
      if (!result) {
        return;
      }

      const labelTranslation = this.editionMode ? 'update' : 'creation';

      try {
        await this.internalTag.save();
        this.$notify({
          type: 'success',
          text: this.$t('notif-success-tag-' + labelTranslation),
        });
        this.$emit('update:active', false);
        this.$emit(this.editionMode ? 'updateTag' : 'addTag', this.internalTag);
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-tag-' + labelTranslation),
        });
      }
    },
  },
};
</script>
