<template>
  <div class="relative">
    <BLoading :is-full-page="false" :active="loading" />
    <template v-if="!loading">
      <h2 class="mb-2">
        {{ $t('currently') }}
      </h2>
      <BMessage
        v-if="!currentStats"
        type="is-danger"
        has-icon
        icon-size="is-small"
      >
        {{ $t('failed-fetch-current-stats') }}
      </BMessage>
      <template v-else>
        <div class="level">
          <div class="level-item has-text-centered">
            <div>
              <p>
                {{ $t('online-users') }}
              </p>
              <p class="title">
                {{ currentStats.users }}
              </p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p>
                {{ $t('projects-active') }}
              </p>
              <p class="title">
                {{ currentStats.projects }}
              </p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p>
                {{ $t('used-storage-space') }}
              </p>
              <p class="title">
                <template v-if="storageStats">
                  {{ Math.round(storageStats.usedP * 100) }}%
                </template>
                <template v-else> ? </template>
              </p>
            </div>
          </div>
        </div>
        <p v-if="currentStats.mostActiveProject">
          <strong>{{ $t('project-most-active') }}</strong
          >:
          <RouterLink
            :to="`/project/${currentStats.mostActiveProject.project.id}`"
          >
            {{ currentStats.mostActiveProject.project.name }}
          </RouterLink>
          ({{
            $tc('count-active-users', currentStats.mostActiveProject.users, {
              count: currentStats.mostActiveProject.users,
            })
          }})
        </p>
      </template>

      <hr />

      <h2 class="mb-2">
        {{ $t('total') }}
      </h2>
      <BMessage
        v-if="!totalCounts"
        type="is-danger"
        has-icon
        icon-size="is-small"
      >
        {{ $t('failed-fetch-total-counts') }}
      </BMessage>
      <div v-else class="columns">
        <div class="column">
          <table class="table is-fullwidth">
            <tbody>
              <tr>
                <td align="right" class="weight-6">
                  {{ totalCounts.users }}
                </td>
                <td>{{ $t('users') }}</td>
              </tr>
              <tr>
                <td align="right" class="weight-6">
                  {{ totalCounts.projects }}
                </td>
                <td>{{ $t('studies') }}</td>
              </tr>
              <tr>
                <td align="right" class="weight-6">
                  {{ totalCounts.images }}
                </td>
                <td>{{ $t('images') }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="column">
          <table class="table is-fullwidth">
            <tbody>
              <tr>
                <td align="right" class="weight-6">
                  {{ totalCounts.ontologies }}
                </td>
                <td>{{ $t('ontologies') }}</td>
              </tr>
              <tr>
                <td align="right" class="weight-6">
                  {{ totalCounts.terms }}
                </td>
                <td>{{ $t('terms') }}</td>
              </tr>
              <tr>
                <td align="right" class="weight-6">
                  {{ totalCounts.userAnnotations }}
                </td>
                <td>{{ $t('user-annotations') }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="column">
          <table class="table is-fullwidth">
            <tbody>
              <tr>
                <td align="right" class="weight-6">
                  {{ totalCounts.softwares }}
                </td>
                <td>{{ $t('digital-assays') }}</td>
              </tr>
              <tr>
                <td align="right" class="weight-6">
                  {{ totalCounts.jobs }}
                </td>
                <td>{{ $t('analyses') }}</td>
              </tr>
              <tr>
                <td align="right" class="weight-6">
                  {{ totalCounts.jobAnnotations }}
                </td>
                <td>{{ $t('analysis-annotations') }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <hr />
      <h2 class="mb-2">
        {{ $t('last-connections') }}
      </h2>

      <div class="flex align-center">
        <strong class="mr-4">{{ $t('period') }}</strong>
        <BSelect v-model="selectedChartOption">
          <option
            v-for="option in chartOptions"
            :key="option.label"
            :value="option"
          >
            {{ option.label }}
          </option>
        </BSelect>
      </div>

      <LastConnectionsChart
        css-classes="chart mt-6 h-320"
        :start-date="selectedChartOption.startDate"
        :period="selectedChartOption.period"
      />
    </template>
  </div>
</template>

<script>
import { Cytomine } from 'cytomine-client';
import LastConnectionsChart from '@/components/charts/LastConnectionsChart.js';
import dayjs from 'dayjs';

export default {
  name: 'AdminDashboard',
  components: { LastConnectionsChart },
  data() {
    return {
      loading: true,
      currentStats: null,
      totalCounts: null,
      storageStats: null,
      chartOptions: [],
      selectedChartOption: null,
    };
  },
  created() {
    const chartOptions = [
      {
        interval: 'day',
        period: 'hour',
      },
      {
        interval: 'week',
        period: 'day',
      },
      {
        interval: 'month',
        period: 'week',
      },
      {
        interval: 'year',
        period: 'week',
      },
    ];
    this.chartOptions = chartOptions.map((option) => {
      const startDate = dayjs()
        .add(1, option.period)
        .subtract(1, option.interval)
        .startOf(option.period)
        .valueOf();
      return {
        label: this.$t(`last-${option.interval}`),
        period: option.period,
        startDate,
      };
    });
    this.selectedChartOption = this.chartOptions[0];
  },
  async activated() {
    this.loading = true;
    await Promise.all(
      [
        this.fetchCurrentStats(),
        this.fetchTotalCounts(),
        this.fetchStorageStats(),
      ].map((p) => p.catch((e) => console.log(e)))
    ); // ignore errors (handled in template) and ensure all promises finish, even if some errors occur in the process
    this.loading = false;
  },
  methods: {
    async fetchCurrentStats() {
      this.currentStats = null; // reset to null so that we know if an error occurred (if so, variable remains null)
      this.currentStats = await Cytomine.instance.fetchCurrentStats();
    },
    async fetchTotalCounts() {
      this.totalCounts = null;
      this.totalCounts = await Cytomine.instance.fetchTotalCounts();
    },
    async fetchStorageStats() {
      this.storageStats = null;
      this.storageStats = await Cytomine.instance.fetchStorageStats();
    },
  },
  i18n: {
    messages: {
      en: {
        'projects-active': 'Active projects',
        'project-most-active': 'Most active project',
      },
      fr: {
        'projects-active': 'Projets actifs',
        'project-most-active': 'Projet le plus actif',
      },
    },
  },
};
</script>
