<template>
  <form @submit.prevent="save()">
    <CytomineModal
      :active="active"
      :title="title"
      @close="$emit('update:active', false)"
    >
      <!-- HACK: fake fields to prevent autofill -->
      <input id="username" class="hidden" type="text" />
      <input id="password" class="hidden" type="password" />

      <b-field
        :key="'username'"
        :label="$t('username')"
        :type="{ 'is-danger': errors.has('username') }"
        :message="errors.first('username')"
        horizontal
      >
        <b-input
          v-model="internalUser['username']"
          v-validate="'required'"
          :name="'username'"
          :type="'text'"
          :disabled="internalUser.id"
        />
      </b-field>

      <b-field
        v-for="{ field, validationRules } in editableFields"
        :key="field"
        :label="
          $t(field === 'password' && editionMode ? 'password-new' : field)
        "
        :type="{ 'is-danger': errors.has(field) }"
        :message="errors.first(field)"
        horizontal
      >
        <b-input
          v-model="internalUser[field]"
          v-validate="validationRules"
          :name="field"
          :type="field === 'password' ? 'password' : 'text'"
          :password-reveal="field === 'password'"
        />
      </b-field>

      <b-field :label="$t('role')" horizontal>
        <b-select v-model="selectedRole">
          <option v-for="(value, key) in roles" :key="key" :value="key">
            {{ $t(value.label) }}
          </option>
        </b-select>
      </b-field>

      <b-field :label="$t('language')" horizontal>
        <b-select v-model="internalUser['language']">
          <option
            v-for="{ value, name } in languages"
            :key="value"
            :value="value"
          >
            {{ name }}
          </option>
        </b-select>
      </b-field>

      <template #footer>
        <button
          class="button"
          type="button"
          @click="$emit('update:active', false)"
        >
          {{ $t('cancel') }}
        </button>
        <button :disabled="errors.any()" class="button is-link">
          {{ $t('save') }}
        </button>
      </template>
    </CytomineModal>
  </form>
</template>

<script>
import { User, RoleCollection } from 'cytomine-client';
import { rolesMapping } from '@/utils/role-utils';
const defaultRole = 'ROLE_GUEST';
const defaultLanguage = {
  value: 'EN',
  name: 'English',
};

import CytomineModal from '@/components/utils/CytomineModal';

export default {
  name: 'UserModal',
  components: { CytomineModal },
  props: {
    active: Boolean,
    user: Object,
  },
  $_veeValidate: { validator: 'new' },
  data() {
    return {
      internalUser: {},
      rolesWithIds: null,
      selectedRole: defaultRole,
      displayErrors: false,
      languages: [
        {
          value: 'EN',
          name: 'English',
        },
        {
          value: 'FR',
          name: 'Français',
        },
      ],
    };
  },
  computed: {
    roles() {
      return rolesMapping;
    },
    editionMode() {
      return Boolean(this.user);
    },
    title() {
      return this.$t(this.editionMode ? 'update-user' : 'create-user');
    },
    editableFields() {
      return [
        {
          field: 'firstname',
          validationRules: 'required',
        },
        {
          field: 'lastname',
          validationRules: 'required',
        },
        // {field: 'username', validationRules: 'required'},
        {
          field: 'email',
          validationRules: 'required|email',
        },
        {
          field: 'password',
          validationRules: this.editionMode ? 'min:8' : 'required|min:8',
        },
      ];
    },
    idRole() {
      return this.rolesWithIds.find(
        (role) => role.authority === this.selectedRole
      ).id;
    },
  },
  watch: {
    active(val) {
      if (val) {
        if (!this.rolesWithIds) {
          this.$notify({
            type: 'error',
            text: this.$t('unexpected-error-info-message'),
          });
          this.$emit('update:active', false);
          return;
        }
        this.internalUser = this.user ? this.user.clone() : new User();
        this.selectedRole = this.user ? this.user.role : defaultRole;
        this.internalUser.language = this.user
          ? this.user.language
          : defaultLanguage.value;
        this.displayErrors = false;
      }
    },
  },
  async created() {
    try {
      this.rolesWithIds = (await RoleCollection.fetchAll()).array;
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    async save() {
      const result = await this.$validator.validateAll();
      if (!result) {
        return;
      }

      const labelTranslation = this.editionMode ? 'update' : 'creation';

      try {
        await this.internalUser.save();
        if (!this.editionMode || this.selectedRole !== this.user.role) {
          await this.internalUser.defineRole(this.idRole);
          this.internalUser.role = this.selectedRole; // for correct rendering in list
        }
        if (this.editionMode && this.internalUser.password) {
          await this.internalUser.savePassword(this.internalUser.password);
        }
        this.internalUser.password = ''; // reinitialize password so that if modal reopened, field empty
        this.$notify({
          type: 'success',
          text: this.$t('notif-success-user-' + labelTranslation),
        });
        this.$emit('update:active', false);
        this.$emit(
          this.editionMode ? 'updateUser' : 'addUser',
          this.internalUser
        );
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text:
            this.$t('notif-error-user-' + labelTranslation) +
            ': ' +
            error.response.data.errors,
        });
      }
    },
  },
};
</script>

<style scoped>
.hidden {
  display: none;
}

>>> .modal-card,
>>> .modal-card-body {
  width: 100vw;
  max-width: 800px;
}
</style>
