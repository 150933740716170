<template>
  <div>
    <h2 class="mb-2">
      {{ $t('welcome-message') }}
    </h2>
    <CytomineQuillEditor v-model="welcomeConfig.value" />
    <p class="text-right">
      <IdxBtn color="blue" class="mt-4" @click="save">
        {{ $t('save') }}
      </IdxBtn>
    </p>
  </div>
</template>

<script>
import CytomineQuillEditor from '@/components/form/CytomineQuillEditor';
import { Configuration } from 'cytomine-client';
import constants from '@/utils/constants.js';

export default {
  name: 'AdminConfiguration',
  components: { CytomineQuillEditor },
  data() {
    return {
      welcomeConfig: new Configuration({
        key: constants.CONFIG_KEY_WELCOME,
        value: '',
        readingRole: 'all',
      }),
    };
  },
  async created() {
    try {
      await this.welcomeConfig.fetch();
    } catch (error) {
      // no welcome message currently set
    }
  },
  methods: {
    async save() {
      try {
        if (!this.welcomeConfig.value) {
          await this.welcomeConfig.delete();
        } else {
          await this.welcomeConfig.save();
        }
        this.$notify({
          type: 'success',
          text: this.$t('notif-success-welcome-message-update'),
        });
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-welcome-message-update'),
        });
      }
    },
  },
};
</script>

<style scoped>
>>> .cytomine-quill-editor {
  min-height: 25em !important;
  max-height: 25em !important;
}
</style>
