import { Bar } from 'vue-chartjs';
import { ProjectConnectionCollection } from 'cytomine-client';
import dayjs from 'dayjs';

export default {
  name: 'last-connections-chart',
  extends: Bar,
  props: {
    startDate: Number,
    endDate: Number,
    period: String,
    project: Number,
    user: Number,
    showDates: {
      type: Boolean,
      default: false,
    },
    revision: Number,
  },
  data() {
    return {
      chartData: null,
      projectConnections: null,
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    queryParams() {
      this.revision; // update of revision value will trigger re-evaluation of queryParams => new object => fetch data
      return {
        afterThan: this.startDate,
        beforeThan: this.endDate,
        period: this.period,
        user: this.user,
        project: this.project,
      };
    },
  },
  watch: {
    async queryParams() {
      await this.fetchData();
      this.updateChart();
    },
    locale() {
      this.updateLabels();
      this.updateChart();
    },
  },
  methods: {
    async fetchData() {
      const connections = (
        await ProjectConnectionCollection.fetchConnectionsFrequency(
          this.queryParams
        )
      ).sort();
      connections.sort((a, b) => (Number(a.time) < Number(b.time) ? -1 : 1));

      const allConnections = [];
      const lastDate = this.endDate ? dayjs(this.endDate) : dayjs();
      let iterDate = dayjs(this.startDate);
      let indexConnection = 0;

      while (iterDate.isBefore(lastDate)) {
        if (
          connections[indexConnection] &&
          iterDate.isSame(
            Number(connections[indexConnection].time),
            this.period
          )
        ) {
          allConnections.push(connections[indexConnection]);
          indexConnection++;
        } else {
          allConnections.push({
            time: iterDate.valueOf(),
            frequency: 0,
          });
        }
        iterDate = iterDate.add(1, this.period);
      }
      this.projectConnections = allConnections;
      this.chartData.datasets[0].data = this.projectConnections.map(
        (item) => item.frequency
      );
      this.updateLabels();
    },
    updateLabels() {
      this.chartData.labels = this.projectConnections.map((item) => {
        const time = Number(item.time);
        switch (this.period) {
          case 'hour':
            return (
              dayjs(time).format(this.showDates ? 'll LT' : 'LT') +
              ' - ' +
              dayjs(time).add(1, 'hour').format('LT')
            );
          case 'day':
            return dayjs(time).format(this.showDates ? 'll' : 'dddd');
          case 'week':
            return (
              dayjs(time).format('ll') +
              ' - ' +
              dayjs.min(dayjs(), dayjs(time).endOf('week')).format('ll')
            );
        }
      });
    },
    async updateChart() {
      this.$data._chart.update();
    },
  },
  async mounted() {
    this.chartData = {
      labels: [],
      datasets: [
        {
          label: this.$t('project-connections'),
          data: [],
          backgroundColor: '#4480c4',
          borderWidth: 0,
        },
      ],
    };

    await this.fetchData();

    this.renderChart(this.chartData, {
      maintainAspectRatio: false,
      legend: { display: false },
      scales: {
        yAxes: [
          {
            ticks: {
              min: 0,
            },
          },
        ],
        xAxes: [
          {
            categoryPercentage: 0.6,
            gridLines: {
              display: false,
            },
          },
        ],
      },
    });
  },
};
