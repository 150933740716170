<template>
  <div>
    <BLoading :is-full-page="false" :active="loading" />
    <template v-if="!loading">
      <BMessage
        v-if="!trustedSources"
        type="is-danger"
        has-icon
        icon-size="is-small"
      >
        <h2 class="mb-3">
          {{ $t('error') }}
        </h2>
        <p>{{ $t('unexpected-error-info-message') }}</p>
      </BMessage>
      <template v-else>
        <div class="columns">
          <div class="column is-one-quarter">
            <BInput
              v-model="searchString"
              :placeholder="$t('search')"
              type="search"
              icon="search"
            />
          </div>

          <div class="column is-one-half has-text-right-desktop">
            <button class="button is-link" @click="startSourceCreation()">
              {{ $t('button-new-trusted-source') }}
            </button>
          </div>
        </div>

        <BTable
          :data="filteredTrustedSources"
          paginated
          :per-page="perPage"
          pagination-size="is-small"
        >
          <BTableColumn
            v-slot="props"
            :label="$t('trusted-source')"
            field="source"
            width="400"
          >
            <SoftwareSource :source="props.row" />
          </BTableColumn>

          <BTableColumn v-slot="props" label="" width="50">
            <div class="buttons">
              <button
                class="button is-link is-small"
                @click="startSourceEdition(props.row)"
              >
                {{ $t('edit') }}
              </button>
              <button
                class="button is-link is-small"
                @click="refresh(props.row)"
              >
                {{ $t('button-refresh') }}
              </button>
            </div>
          </BTableColumn>

          <template #empty>
            <div class="content has-text-grey has-text-centered">
              <p>{{ $t('no-trusted-source-fitting-criteria') }}</p>
            </div>
          </template>

          <template #bottom-left>
            <BSelect v-model="perPage" size="is-small">
              <option value="10">
                {{ $t('count-per-page', { count: 10 }) }}
              </option>
              <option value="25">
                {{ $t('count-per-page', { count: 25 }) }}
              </option>
              <option value="50">
                {{ $t('count-per-page', { count: 50 }) }}
              </option>
              <option value="100">
                {{ $t('count-per-page', { count: 100 }) }}
              </option>
            </BSelect>
          </template>
        </BTable>

        <TrustedSourceModal
          :active.sync="modal"
          :source="editedSource"
          @addSource="addSource"
          @updateSource="updateSource"
        />
      </template>
    </template>
  </div>
</template>

<script>
import { TrustedSourceCollection } from 'cytomine-client';
import TrustedSourceModal from './TrustedSourceModal';
import { getWildcardRegexp } from '@/utils/string-utils';
import SoftwareSource from '@/components/software/SoftwareSource';

export default {
  name: 'AdminSoftware',
  components: {
    SoftwareSource,
    TrustedSourceModal,
  },
  data() {
    return {
      loading: true,
      trustedSources: null,
      addSourceModal: false,
      searchString: '',
      perPage: 25,
      modal: false,
      editedSource: null,
    };
  },
  computed: {
    regexp() {
      return getWildcardRegexp(this.searchString);
    },
    filteredTrustedSources() {
      if (!this.searchString) {
        return this.trustedSources;
      }

      return this.trustedSources.filter(
        (ts) =>
          this.regexp.test(ts.name) ||
          this.regexp.test(ts.username) ||
          this.regexp.test(ts.provider) ||
          this.regexp.test(ts.dockerUsername) ||
          this.regexp.test(ts.environmentProvider)
      );
    },
  },
  async created() {
    try {
      const results = await TrustedSourceCollection.fetchAll();
      const sources = results.array;
      sources.forEach((source) => this.formatSource(source));
      this.trustedSources = sources;
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
  },
  methods: {
    formatSource(source) {
      source.environmentProvider = 'docker';
    },
    async refresh(source) {
      try {
        await source.refresh();
        this.$notify({
          type: 'success',
          text: this.$t('notif-success-source-refresh'),
        });
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-source-refresh'),
        });
      }
    },
    startSourceCreation() {
      this.editedSource = null;
      this.modal = true;
    },
    addSource(source) {
      this.formatSource(source);
      this.trustedSources.push(source);
    },
    startSourceEdition(source) {
      this.editedSource = source;
      this.modal = true;
    },
    updateSource(source) {
      this.formatSource(source);
      this.editedSource.populate(source);
    },
  },
};
</script>
