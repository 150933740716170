export const rolesMapping = Object.freeze({
  ROLE_GUEST: {
    label: 'guest',
    index: 0,
    class: 'is-light',
  },
  ROLE_USER: {
    label: 'user',
    index: 1,
    class: 'is-link',
  },
  ROLE_ADMIN: {
    label: 'admin',
    index: 2,
    class: 'is-success',
  },
  ROLE_SUPER_ADMIN: {
    label: 'super-admin',
    index: 3,
    class: 'is-success',
  },
});
