<template>
  <form @submit.prevent="save()">
    <CytomineModal
      :active="active"
      :title="title"
      @close="$emit('update:active', false)"
    >
      <b-field
        v-for="{ field, validationRules, disabled } in editableFields"
        :key="field"
        :label="$t(fieldLabels[field])"
        :type="{ 'is-danger': errors.has(field) }"
        :message="errors.first(field)"
        horizontal
      >
        <b-input
          v-model="internalSource[field]"
          v-validate="validationRules"
          :name="field"
          :disabled="disabled"
          placeholder=""
        />
      </b-field>

      <template #footer>
        <button
          class="button"
          type="button"
          @click="$emit('update:active', false)"
        >
          {{ $t('cancel') }}
        </button>
        <button :disabled="errors.any()" class="button is-link">
          {{ $t('save') }}
        </button>
      </template>
    </CytomineModal>
  </form>
</template>

<script>
import { TrustedSource } from 'cytomine-client';

import CytomineModal from '@/components/utils/CytomineModal';

export default {
  name: 'TrustedSourceModal',
  components: { CytomineModal },
  props: {
    active: Boolean,
    source: Object,
  },
  $_veeValidate: { validator: 'new' },
  data() {
    return {
      internalSource: {},
      displayErrors: false,
    };
  },
  computed: {
    editionMode() {
      return Boolean(this.source);
    },
    title() {
      return this.$t(this.editionMode ? 'update-source' : 'create-source');
    },
    editableFields() {
      return [
        {
          field: 'username',
          validationRules: 'required',
          disabled: false,
        },
        {
          field: 'dockerUsername',
          validationRules: 'required',
          disabled: false,
        },
        {
          field: 'prefix',
          validationRules: '',
          disabled: false,
        },
        {
          field: 'provider',
          validationRules: 'required',
          disabled: true,
        },
        {
          field: 'environmentProvider',
          validationRules: 'required',
          disabled: true,
        },
      ];
    },
    fieldLabels() {
      return {
        provider: 'source-provider',
        username: 'source-provider-name',
        environmentProvider: 'environment-provider',
        dockerUsername: 'environment-provider-name',
        prefix: 'prefix',
      };
    },
  },
  watch: {
    active(val) {
      if (val) {
        this.internalSource = this.formatSource(
          this.source ? this.source.clone() : new TrustedSource()
        );
        this.displayErrors = false;
      }
    },
  },
  methods: {
    async save() {
      const result = await this.$validator.validateAll();
      if (!result) {
        return;
      }

      const labelTranslation = this.editionMode ? 'update' : 'creation';

      try {
        const source = await this.internalSource.save();
        this.internalSource = this.formatSource(source);
        this.$notify({
          type: 'success',
          text: this.$t('notif-success-source-' + labelTranslation),
        });
        this.$emit('update:active', false);
        this.$emit(
          this.editionMode ? 'updateSource' : 'addSource',
          this.internalSource
        );
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-source-' + labelTranslation),
        });
      }
    },
    formatSource(source) {
      source.environmentProvider = 'docker';
      source.provider = !source.provider ? 'github' : source.provider;
      return source;
    },
  },
};
</script>

<style scoped>
>>> .modal-card,
>>> .modal-card-body {
  width: 100vw;
  max-width: 800px;
}
</style>
